// eslint-disable-next-line @nx/enforce-module-boundaries
import dynamic from 'next/dynamic';

export default {
  // Navigation
  BreadCrumbs: dynamic(() => import('@sitecore/components/content/Breadcrumbs/Breadcrumbs')),
  FooterMenu: dynamic(() => import('@sitecore/components/content/Footer/FooterMenu')),
  Footer: dynamic(() => import('@sitecore/components/content/Footer/Footer')),
  LanguageSelector: dynamic(() => import('@sitecore/components/content/LanguageSelector/LanguageSelector')),
  Logo: dynamic(() => import('@sitecore/components/eneco/ImageLink/ImageLink')),
  MainNavigation: dynamic(() => import('@apps/header/shared/multilabel/src/Header')),
  MegaMenu: dynamic(() => import('@sitecore/components/content/MegaMenu/MegaMenu')),
  MegaMenuV2: dynamic(() => import('@sitecore/components/content/MegaMenuV2/MegaMenuV2')),
  MegaMenuDivision: dynamic(() => import('@sitecore/components/content/MegaMenuDivision/MegaMenuDivision')),
  MessageBar: dynamic(() => import('@sitecore/components/content/MessageBar/MessageBar')),
  NotificationsBar: dynamic(() => import('@sitecore/components/content/NotificationsBar/NotificationsBar')),
  SubFooterMenu: dynamic(() => import('@sitecore/components/content/Footer/SubFooterMenu')),
  SocialMediaMenu: dynamic(() => import('@sitecore/components/content/Footer/SocialMediaMenu')),
  // Content components
  ContentCategories: dynamic(() => import('@sitecore/components/content/ContentCategories/ContentCategories')),
  ChatLinks: dynamic(() => import('@sitecore/components/content/ChatLinks/ChatLinks')),
  Accordion: dynamic(() => import('@sitecore/components/content/Accordion/Accordion')),
  Hero: dynamic(() => import('@sitecore/components/content/Hero/Hero')),
  NotificationBox: dynamic(() => import('@sitecore/components/content/NotificationBox/NotificationBox')),
  RichText: dynamic(() => import('@sitecore/components/content/RichText/RichText')),
  TopTaskCTA: dynamic(() => import('@sitecore/components/content/TopTaskCTA/TopTaskCTA')),
  TopTasks: dynamic(() => import('@sitecore/components/content/TopTasks/TopTasks')),
  ContactCustomerService: dynamic(
    () => import('@sitecore/components/content/ContactCustomerService/ContactCustomerService'),
  ),
  CustomerCareSearchTest: dynamic(
    () => import('@sitecore/components/content/CustomerCareSearchTest/CustomerCareSearchTest'),
  ),
  IconList: dynamic(() => import('@sitecore/components/content/IconList/IconList')),
  Image: dynamic(() => import('@sitecore/components/content/Image/Image')),
  List: dynamic(() => import('@sitecore/components/content/List/List')),
  HeroCard: dynamic(() => import('@sitecore/components/content/HeroCard/HeroCard')),
  LinkCard: dynamic(() => import('@sitecore/components/content/LinkCard/LinkCard')),
  FeaturePreview: dynamic(() => import('@sitecore/components/content/FeaturePreview/FeaturePreview')),
  Price: dynamic(() => import('@sitecore/components/content/Price/Price')),
  StoreButtons: dynamic(() => import('@sitecore/components/content/StoreButtons/StoreButtons')),
  SimpleTable: dynamic(() => import('@sitecore/components/content/SimpleTable/SimpleTable')),
  TextStreamer: dynamic(() => import('@sitecore/components/content/TextStreamer/TextStreamer')),
  UsabillaFeedback: dynamic(() => import('@sitecore/components/system/UsabillaFeedback/UsabillaFeedback')),
  WeDoenHetNuTestimonialsCard: dynamic(
    () => import('@sitecore/components/content/WeDoenHetNuTestimonialsCard/WeDoenHetNuTestimonialsCard'),
  ),
  AddressFinder: dynamic(() => import('@sitecore/components/content/AddressFinder/AddressFinder')),
  AddressFinderWithMovingOption: dynamic(
    () => import('@sitecore/components/content/AddressFinder/AddressFinderWithMovingOption'),
  ),
  SmeAddressFinder: dynamic(() => import('@sitecore/components/content/AddressFinder/SmeAddressFinder')),
  AffiliateCalculationTool: dynamic(() => import('@apps/flows/nl/eneco/energyFlow/src/CalculationTool')),

  AdvancedCalculationTool: dynamic(() => import('@apps/flows/nl/eneco/energyFlow/src/AdvancedCalculationTool')),

  StepExplainer: dynamic(() => import('@sitecore/components/content/StepExplainerVertical/StepExplainerVertical')),
  CreditWarningBar: dynamic(() => import('@sitecore/components/content/CreditWarningBar/CreditWarningBar')),
  ShareOnSocials: dynamic(() => import('@sitecore/components/content/ShareOnSocials/ShareOnSocials')),
  PaginatedContent: dynamic(() => import('@sitecore/components/content/PaginatedContent/PaginatedContent')),
  EnergyStatisticsByRegion: dynamic(
    () => import('@sitecore/components/content/EnergyStatistics/EnergyStatisticsByRegion'),
  ),
  EnergyStatisticsForRegion: dynamic(
    () => import('@sitecore/components/content/EnergyStatistics/EnergyStatisticsForRegion'),
  ),
  FlowNextBestActionCTA: dynamic(() => import('@apps/flowNextBestAction/nl/eneco/src/FlowNextBestAction')),
  ButtonLink: dynamic(() => import('@sitecore/components/content/ButtonLink/ButtonLink')),
  ButtonLineUp: dynamic(() => import('@sitecore/components/content/ButtonLineUp/ButtonLineUp')),

  // Apps
  Calculator: dynamic(() => import('@apps/calculator/nl/eneco/src/Calculator')),
  HeatComparisonTool: dynamic(() => import('@apps/calculator/nl/eneco/src/HeatComparisonCalculator')),
  CustomerReview: dynamic(() => import('@apps/customerreview/shared/multilabel/src/CustomerReview')),
  DynamicPriceCardElectricityV2: dynamic(
    () => import('@sitecore/components/content/DynamicPriceCard/DynamicPriceCardElectricityV2'),
  ),
  DynamicPriceCardGasV2: dynamic(() => import('@sitecore/components/content/DynamicPriceCard/DynamicPriceCardGasV2')),
  DynamicPricingTariffs: dynamic(
    () => import('@apps/dynamicpricingtariffs/shared/multilabel/src/DynamicPricingTariffs/DynamicPricingTariffs'),
  ),
  InspirationAdviceFilter: dynamic(() => import('@apps/inspirationadvicefilter/nl/eneco/src/InspirationAdviceFilter')),
  ShopWindow: dynamic(() => import('@apps/shopwindow/nl/eneco/src/ShopWindow')),
  IDealPaymentV2: dynamic(() => import('@apps/payment/shared/multilabel/src/IDealPayment/IDealPayment')),

  // Other
  CookieWall: dynamic(() => import('@apps/cookiewall/nl/multilabel/src/CookieWall')),
  ErrorPage: dynamic(() => import('@sitecore/components/content/ErrorPage/ErrorPage')),
  GTM: dynamic(() => import('@apps/gtm/shared/multilabel/src')),
  MetaData: dynamic(() => import('@sitecore/components/system/MetaData/MetaData')),
  MetaTags: dynamic(() => import('@sitecore/components/system/MetaTags/MetaTags')),
  StructuredDataSchema: dynamic(() => import('@sitecore/components/system/StructuredDataSchema/StructuredDataSchema')),
  Chat: dynamic(() => import('@apps/chat/shared/multilabel/src/Chat')),
  ContractConfirmation: dynamic(() => import('@sitecore/components/content/ContractConfirmation/ContractConfirmation')),
  Unsubscribe: dynamic(() => import('@apps/unsubscribe/shared/multilabel/src/Unsubscribe')),
  Divider: dynamic(() => import('@sitecore/components/eneco/Divider/Divider')),

  // Wrapper components
  Article: dynamic(() => import('@sitecore/components/content/Article/Article')),
  Centered: dynamic(() => import('@sitecore/components/content/Centered/Centered')),
  Section: dynamic(() => import('@sitecore/components/content/Section/Section')),
  LineUp: dynamic(() => import('@sitecore/components/content/LineUp/LineUp')),
  NavigationView: dynamic(() => import('@sitecore/components/content/NavigationView/NavigationView')),
  SplitView: dynamic(() => import('@sitecore/components/content/SplitView/SplitView')),
  Carousel: dynamic(() => import('@sitecore/components/content/Carousel/Carousel')),

  // Wrappable components
  ArticleCard: dynamic(() => import('@sitecore/components/content/ArticleCard/ArticleCard')),
  ArticleContentCard: dynamic(() => import('@sitecore/components/content/ArticleContentCard/ArticleContentCard')),
  ContentCard: dynamic(() => import('@sitecore/components/content/ContentCard/ContentCard')),
  ProductCard: dynamic(() => import('@sitecore/components/content/ProductCard/ProductCard')),
  StickyNav: dynamic(() => import('@sitecore/components/content/StickyNav/StickyNav')),
  StukjeZonCalculationTool: dynamic(() => import('@sitecore/components/content/StukjeZon/StukjeZon')),
  Table: dynamic(() => import('@sitecore/components/content/Table/Table')),
  TitleTextCTA: dynamic(() => import('@sitecore/components/content/TitleTextCTA/TitleTextCTA')),
  PersonalizedTitleTextCTA: dynamic(
    () => import('@sitecore/components/content/PersonalizedTitleTextCTA/PersonalizedTitleTextCTA'),
  ),
  Checklist: dynamic(() => import('@sitecore/components/content/Checklist/Checklist')),
  USPItem: dynamic(() => import('@sitecore/components/content/USPItem/USPItem')),
  Video: dynamic(() => import('@sitecore/components/content/Video/Video')),

  // Appointments
  'appointments-flow-v2': dynamic(() => import('@apps/appointmentsV2/shared/multilabel/src/AppointmentsFlow')),

  // MeterReadings
  'meterreadings-flow': dynamic(() => import('@apps/meterreadings/nl/multilabel/src/MeterReadingsFlow')),

  // AddressFinder Example
  ExampleAddressFinderForm: dynamic(
    () => import('@sitecore/components/content/ExampleAddressFinderForm/ExampleAddressFinderForm'),
  ),

  // EnergyRating
  RegistrationAccountCreate: dynamic(
    () => import('@apps/accessmanagement/nl/multilabel/src/components/RegistrationFlow/RegistrationFlow'),
  ),
  RegistrationAccountReset: dynamic(
    () => import('@apps/accessmanagement/nl/multilabel/src/components/RegistrationFlow/RegistrationFlow'),
  ),
  RegistrationFooter: dynamic(
    () => import('@apps/accessmanagement/nl/multilabel/src/components/RegistrationFooter/RegistrationFooter'),
  ),
  RegistrationHeader: dynamic(
    () => import('@apps/accessmanagement/nl/multilabel/src/components/RegistrationHeader/RegistrationHeader'),
  ),

  // EmailRequest
  EmailRequest: dynamic(() => import('@components/EmailRequest/EmailRequest')),

  // Heatpump shop window
  HeatPumpSpecTable: dynamic(() => import('@apps/shopwindow/nl/eneco/src/components/HeatPumpSpecTable')),
  PriceDetailsTable: dynamic(() => import('@apps/shopwindow/nl/eneco/src/components/PriceDetailsTable')),

  PaymentMethods: dynamic(() => import('@apps/shopwindow/nl/eneco/src/components/PaymentMethods')),

  // Campaign components
  WeDoenHetNuCard: dynamic(() => import('@sitecore/components/content/Road2Zero/WeDoenHetNuCard/WeDoenHetNuCard')),
  WeDoenHetNuTextImage: dynamic(
    () => import('@sitecore/components/content/Road2Zero/WeDoenHetNuTextImage/WeDoenHetNuTextImage'),
  ),

  // Forms
  GenericForm: dynamic(() => import('@sitecore/components/forms/GenericForm/GenericForm')),
  GenericFormAddressField: dynamic(
    () => import('@sitecore/components/forms/GenericForm/Components/GenericFormAddress/GenericFormAddressField'),
  ),
  GenericFormNameField: dynamic(
    () => import('@sitecore/components/forms/GenericForm/Components/GenericFormNameField/GenericFormNameField'),
  ),
  GenericFormParagraph: dynamic(
    () => import('@sitecore/components/forms/GenericForm/Components/GenericFormParagraph/GenericFormParagraph'),
  ),
  GenericFormSalutationField: dynamic(
    () => import('@sitecore/components/forms/GenericForm/Components/GenericFormSalutation/GenericFormSalutation'),
  ),
  GenericFormRadioGroup: dynamic(
    () => import('@sitecore/components/forms/GenericForm/Components/GenericFormRadioGroup/GenericFormRadioGroup'),
  ),
  GenericFormCheckboxField: dynamic(
    () => import('@sitecore/components/forms/GenericForm/Components/GenericFormCheckboxField/GenericFormCheckboxField'),
  ),
  GenericFormCheckboxGroup: dynamic(
    () => import('@sitecore/components/forms/GenericForm/Components/GenericFormCheckboxGroup/GenericFormCheckboxGroup'),
  ),
  GenericFormDateField: dynamic(
    () => import('@sitecore/components/forms/GenericForm/Components/GenericFormDateField/GenericFormDateField'),
  ),
  GenericFormEmailInputField: dynamic(
    () =>
      import('@sitecore/components/forms/GenericForm/Components/GenericFormEmailInputField/GenericFormEmailInputField'),
  ),
  GenericFormNumberInputField: dynamic(
    () =>
      import(
        '@sitecore/components/forms/GenericForm/Components/GenericFormNumberInputField/GenericFormNumberInputField'
      ),
  ),
  GenericFormPhoneNumberInputField: dynamic(
    () =>
      import(
        '@sitecore/components/forms/GenericForm/Components/GenericFormPhoneNumberInputField/GenericFormPhoneNumberInputField'
      ),
  ),
  GenericFormInputField: dynamic(
    () => import('@sitecore/components/forms/GenericForm/Components/GenericFormInputField/GenericFormInputField'),
  ),
  GenericFormInputHiddenField: dynamic(
    () =>
      import(
        '@sitecore/components/forms/GenericForm/Components/GenericFormInputHiddenField/GenericFormInputHiddenField'
      ),
  ),
  GenericFormTextAreaInputField: dynamic(
    () =>
      import(
        '@sitecore/components/forms/GenericForm/Components/GenericFormTextAreaInputField/GenericFormTextAreaInputField'
      ),
  ),
  GenericFormInputSelect: dynamic(
    () => import('@sitecore/components/forms/GenericForm/Components/GenericFormInputSelect/GenericFormInputSelect'),
  ),
  GenericFormDivider: dynamic(
    () => import('@sitecore/components/forms/GenericForm/Components/GenericFormDivider/GenericFormDivider'),
  ),
  ContactForm: dynamic(() => import('@sitecore/components/forms/ContactForm/ContactForm')),
  ServiceSignupForm: dynamic(() => import('@sitecore/components/forms/ServiceSignupForm/ServiceSignupForm')),
  HeatDamageForm: dynamic(() => import('@sitecore/components/forms/HeatDamageForm/HeatDamageForm')),
  WarmOptimaalCampaignForm: dynamic(
    () => import('@sitecore/components/forms/WarmOptimaalCampaignForm/WarmOptimaalCampaignForm'),
  ),
  RegisterToNewsletterForm: dynamic(
    () => import('@sitecore/components/forms/RegisterToNewsletterForm/RegisterToNewsletterForm'),
  ),
  RevolutionRegisterToNewsletterForm: dynamic(
    () => import('@sitecore/components/forms/RegisterToNewsletterForm/RevolutionRegisterToNewsletterForm'),
  ),
  EmailRequestWrapper: dynamic(() => import('@sitecore/components/forms/EmailRequestWrapper/EmailRequestWrapper')),
  WarmthInterruptions: dynamic(() => import('@sitecore/components/content/WarmthInterruptions/WarmthInterruptions')),
  WarmthCheckAddressFinder: dynamic(() => import('@sitecore/components/forms/WarmCheckerForm/WarmChecker')),
  // Flows
  'energy-flow': dynamic(() => import('@apps/flows/nl/eneco/energyFlow/src/EnergyFlow')),
  'dynamic-energy-flow': dynamic(() => import('@apps/flows/nl/eneco/energyFlow/src/DynamicEnergyFlow')),
  'urgent-energy-flow': dynamic(() => import('@apps/flows/nl/eneco/energyFlow/src/UrgentEnergyFlow')),
  'contract-extension-flow': dynamic(() => import('@apps/flows/nl/eneco/energyFlow/src/ContractExtensionFlow')),
  'contract-advice-flow': dynamic(() => import('@apps/flows/nl/eneco/contractAdviceFlow/src/ContractAdviceFlow')),
  'lead-flow': dynamic(() => import('@apps/flows/nl/eneco/leadFlow/src/LeadFlow')),
  'solar-lead-flow': dynamic(() => import('@apps/flows/nl/eneco/solarLeadFlow/src/SolarLeadFlow')),
  'leads-additional-questionnaire-flow': dynamic(
    () => import('@apps/flows/nl/eneco/leadsAdditionalQuestionnaireFlow/src/LeadsAdditionalQuestionnaireFlow'),
  ),
  'servicegemak-flow': dynamic(() => import('@apps/flows/nl/eneco/serviceGemakFlow/src/ServiceGemakFlow')),
  'ketelcomfort-flow': dynamic(() => import('@apps/flows/nl/eneco/ketelComfortFlow/src/KetelComfortFlow')),
  'toon-subscription-flow': dynamic(() => import('@apps/flows/nl/eneco/toonFlow/src/ToonSubscriptionFlow')),
  'rental-devices-flow': dynamic(() => import('@apps/flows/nl/eneco/rentalDeviceFlow/src/RentalDeviceFlow')),
  'quote-approval-flow': dynamic(() => import('@apps/flows/nl/eneco/quoteApprovalFlow/src/quoteApprovalFlow')),
  'legislation-compass-flow': dynamic(
    () => import('@apps/flows/nl/eneco/legislationCompassFlow/src/LegislationCompassFlow'),
  ),
  'sme-energy-flow': dynamic(() => import('@apps/flows/nl/eneco/smeEnergyFlow/src/SmeEnergyFlow')),
  'sme-energy-flow-v2': dynamic(() => import('@apps/flows/nl/eneco/smeEnergyFlow/src/SmeEnergyFlowVariant')),
  'peak-price-limit-flow': dynamic(() => import('@apps/flows/nl/eneco/peakPriceLimitFlow/src/PeakPriceLimitFlow')),
  'dongle-flow': dynamic(() => import('@apps/flows/nl/eneco/dongleFlow/src/DongleFlow')),
  'ketelcomfort-cancelation-flow': dynamic(
    () => import('@apps/flows/nl/eneco/ketelComfortCancelationFlow/src/KetelComfortCancelationFlow'),
  ),
  'toon-cancelation-flow': dynamic(() => import('@apps/flows/nl/eneco/toonCancelationFlow/src/ToonCancelationFlow')),
  'heat-pump-suitability-check-flow': dynamic(
    () => import('@apps/flows/nl/eneco/heatpumpSuitabilityCheckFlow/src/HeatpumpSuitabilityCheckFlow'),
  ),
  'deceased-flow': dynamic(() => import('@apps/flows/nl/eneco/deceasedCancelationFlow/src/DeceasedCancelationFlow')),
};
